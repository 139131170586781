module.exports = {
    //moengage specific events
    LOGIN_SUCCESS: "login_success",
    LOGOUT_SUCCESS: "logout_success",
    DEMAT_ADDED: "demat_added",
    QUESTIONNAIRE_SUBMIT: "questionnaire_submit",

    //web only moengage specific events
    SIGNUP_SUCCESS: "signup_success",
    KYC_STARTED: "kyc_started",

    //clicks
    CLICKED_OPP_DETAILS_INVEST_NOW: "clicked_opp_details_invest_now",
    CLICKED_OPP_DETAILS_NON_KYC_INVEST_NOW: "clicked_opp_details_non_kyc_invest_now",
    CLICKED_OPP_DETAILS_REPAYMENT_SCH: "clicked_opp_details_repayment_sch",
    CLICKED_FUND_DECLARATION_ACCEPTED: "clicked_fund_declaration_accepted",
    CLICKED_DISCLAIMER_ACCEPTED: "clicked_disclaimer_accepted",
    CLICKED_INV_OVERVIEW_CONTINUE: "clicked_inv_overview_continue",
    CLICKED_INV_OVERVIEW_REPAYMENT_SCH: "clicked_inv_overview_repayment_sch",
    CLICKED_REVIEW_DEMAT_CONTINUE: "clicked_review_demat_continue",
    CLICKED_SIGN_ONBOARDING_AGREEMENT: "clicked_sign_onboarding_agreement",
    CLICKED_SIGN_AGREEMENT: "clicked_sign_agreement",
    CLICKED_SIGN_AGREEMENT_DOWNLOAD_DOC: "clicked_sign_agreement_download_doc",
    CLICKED_PAYMENT_PAY_SECURELY: "clicked_payment_pay_securely",
    CLICKED_PAYMENT_SUCCESS_DOWNLOAD_DOC: "clicked_payment_success_download_doc",
    CLICKED_PAYMENT_FAILED_RETRY: "clicked_payment_failed_retry",
    CLICKED_PAYMENT_SUCCESS_REPAYMENT_SCH: "clicked_payment_success_repayment_sch",
    CLICKED_REINVESTMENT_PREFERENCE_CONTINUE: "clicked_reinvestment_preference_continue",
    CLICKED_REINVESTMENT_PREFERENCE_SKIP: "clicked_reinvestment_preference_skip",
    CLICKED_NEWS_LETTERS_READ_NOW: "clicked_news_letters_read_now",

    //Page views
    VIEWED_WELCOME_BACK_PAGE: "viewed_welcome_back_page",
    VIEWED_QUESTIONNAIRE_PAGE: "viewed_questionnaire_page",
    VIEWED_PROFILE_PAGE: "viewed_profile_page",
    VIEWED_OPPORTUNITY_LISTING_PAGE: "viewed_opportunity_listing_page",
    VIEWED_OPPORTUNITY_DETAILS_PAGE: "viewed_opportunity_details_page",
    VIEWED_LOGIN_PAGE: "viewed_login_page",
    VIEWED_ALT_SAFE_PAGE: "viewed_alt_safe_page",
    VIEWED_REPAYMENT_PREFERENCE_PAGE: "viewed_repayment_preference_page",
    VIEWED_INVESTMENT_LISTING_PAGE: "viewed_investment_listing_page",
    VIEWED_INVESTMENT_DETAILS_PAGE: "viewed_investment_details_page",
    VIEWED_INCOME_CERTIFICATE_PAGE: "viewed_income_certificate_page",
    VIEWED_DASHBOARD_PAGE: "viewed_dashboard_page",
    VIEWED_MY_REFERRAL_PAGE: "viewed_my_referral_page",
    VIEWED_FUND_DECLARATION_PAGE: "viewed_fund_declaration_page",
    VIEWED_INVESTMENT_DISCLAIMER_PAGE: "viewed_investment_disclaimer_page",
    VIEWED_INVESTMENT_OVERVIEW_PAGE: "viewed_investment_overview_page",
    VIEWED_SIGN_AGREEMENT_PAGE: "viewed_sign_agreement_page",
    VIEWED_DOC_SIGN_PAGE: "viewed_doc_sign_page",
    VIEWED_PAYMENT_OPTIONS_PAGE: "viewed_payment_options_page",
    VIEWED_REINVESTMENT_PREFERENCE_PAGE: "viewed_reinvestment_preference_page",
    VIEWED_REINVESTMENT_DASHBOARD_PAGE: "viewed_reinvestment_dashboard_page",
    VIEWED_REINVESTMENT_DETAILS_PAGE: "viewed_reinvestment_details_page",
    VIEWED_REVIEW_DEMAT_PAGE: "viewed_review_demat_page",
    VIEWED_PAYMENT_SUCCESS_PAGE: "viewed_payment_success_page",
    VIEWED_PAYMENT_FAILED_PAGE: "viewed_payment_failed_page",
    VIEWED_PAYMENT_PENDING_PAGE: "viewed_payment_pending_page",
    VIEWED_REINVESTMENT_DOC_SIGN_PAGE: "viewed_reinvestment_doc_sign_page",
    VIEWED_REINVESTMENT_DOC_SIGN_SUCCESS_PAGE: "viewed_reinvestment_doc_sign_success_page",
    VIEWED_REINVESTMENT_DOC_SIGN_PROGRESS_PAGE: "viewed_reinvestment_doc_sign_in_progress_page",

    //web only Page views
    VIEWED_WELCOME_PAGE: "viewed_welcome_page",
    VIEWED_WEBINAR_PAGE: "viewed_webinar_page",
    VIEWED_TERMS_AND_CONDITION_PAGE: "viewed_terms_and_condition_page",
    VIEWED_TEAM_PAGE: "viewed_team_page",
    VIEWED_MEDIA_PAGE: "viewed_media_page",
    VIEWED_SURVEY_PAGE: "viewed_survey_page",
    VIEWED_ERROR_PAGE: "viewed_error_page",
    VIEWED_KYC_PAGE: "viewed_kyc_page",
    VIEWED_KYC_OVERVIEW_PAGE: "viewed_kyc_overview_page",
    VIEWED_KYC_VERIFIED_PAGE: "viewed_kyc_verified_page",
    VIEWED_MANUAL_VERIFICATION_PAGE: "viewed_kyc_manual_verification_page",
    VIEWED_SIGNUP_PAGE: "viewed_signup_page",
    VIEWED_RISK_DISCLOSURE_PAGE: "viewed_risk_disclosure_page",
    VIEWED_REFER_AND_EARN_PAGE: "viewed_refer_and_earn_page",
    VIEWED_PRIVACY_POLICY_PAGE: "viewed_privacy_policy_page",
    VIEWED_NRI_DISCLAIMER_PAGE: "viewed_nri_disclaimer_page",
    VIEWED_INVITE_ONBOARDING_COMPLETE_PAGE: "viewed_invite_onboarding_complete_page",
    VIEWED_INVITATION_ACCEPT_PAGE: "viewed_invitation_accept_page",
    VIEWED_INVITATION_CONFIRM_PAGE: "viewed_invitation_confirm_page",
    VIEWED_INVITATION_INVALID_PAGE: "viewed_invitation_invalid_page",
    VIEWED_IFA_DISCLAIMER_PAGE: "viewed_ifa_disclaimer_page",
    VIEWED_IFA_CREATE_NEW_ACCOUNT_PAGE: "viewed_ifa_create_new_account_page",
    VIEWED_HOME_PAGE: "viewed_home_page",
    VIEWED_FAQ_PAGE: "viewed_faq_page",
    VIEWED_DISCLAIMER_PAGE: "viewed_disclaimer_page",
    VIEWED_CREDIT_EVALUATION_PROCESS_PAGE: "viewed_credit_evaluation_process_page",
    VIEWED_CAREERS_PAGE: "viewed_careers_page",
    VIEWED_AI_SAFE_PAGE: "viewed_ai_safe_page",
    VIEWED_ABOUT_US_PAGE: "viewed_about_us_page",
    VIEWED_PRODUCTS_PAGE: "viewed_products_page",
    VIEWED_CONTACT_US_PAGE: "viewed_contact_us_page",
    VIEWED_KYC_ACCOUNT_TYPE_PAGE: "viewed_kyc_account_type_page",
    VIEWED_BLOGS_LISTING_PAGE: "viewed_blogs_listing_page",
    VIEWED_BLOGS_DETAIL_PAGE: "viewed_blogs_detail_page",
    VIEWED_PAYMENT_GATEWAY_RESPONSE_PAGE: "viewed_payment_gateway_response_page",
    VIEWED_PAYMENT_GATEWAY_WEB_VIEW_PAGE: "viewed_payment_gateway_web_view_page",
    VIEWED_DOC_SIGN_WEB_VIEW_PAGE: "viewed_doc_sign_web_view_page",
    VIEWED_DOC_SIGN_RESPONSE_WEB_VIEW_PAGE: "viewed_doc_sign_response_web_view_page",
    VIEWED_DOC_SIGN_RESPONSE_PAGE: "viewed_doc_sign_response_page",
    VIEWED_INVESTMENT_PROFILE_PAGE: "viewed_investment_profile_page",
    VIEWED_NEWS_LETTERS_PAGE: "viewed_news_letters_page"
};